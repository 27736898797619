body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-logo {
  width: 100%;
  margin-bottom: 20px;
}


.card-header.item:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

.v2-tabs .nav-item button {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--cui-header-color);;
}


.v2-tabs .nav-item button.active {
  border-bottom: 2px solid #321fdb;
  font-weight: 700;
  color: #321fdb;
}