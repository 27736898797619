.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.is-updating {
  opacity: 0.3;
}

.pagination ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagination li {
  margin: 0 0.25rem;
}

.pagination a {
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: pointer;
}

.pagination a:hover {
  background-color: #eee;
}

.pagination .selected a {
  color: #999;
  border: 1px solid #ccc;
}
.pagination .selected a:hover {
  background-color: inherit;
}

.pagination .active a {
  background-color: #007bff;
  color: #fff;
}

.pagination .disabled a {
  color: #999;
  border: 1px solid #ccc;
  background-color: inherit;
}
