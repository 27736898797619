.date-presets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.date-presets li {
  padding: 5px 25px 5px 25px;
  cursor: pointer;
}

.date-presets li:hover {
  background: #ccc;
}
.react-datepicker {
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
