.v-center {
  display: flex;
  align-items: center;
}
.subtle {
  opacity: 0.6;
}
a {
  text-decoration: none;
}

.footer {
  margin-top: 150px;
}
